import React, {useState} from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import {Carousel} from 'react-carousel-minimal';
import '../components/designed-objects.css'
// import Img from 'gatsby-image'
import '../styles/carousel.css'
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PaintingsGallery from '../components/paintings-gallery'
import FsLightbox from "fslightbox-react";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

//
// const propTypes = {
//     data: PropTypes.object.isRequired,
// }

// class DesignedObject extends React.Component {
const DesignedObject = ({data}) => {

    const [toggler, setToggler] = useState({toggler: false, slide: false});
    // const DesignedObjects = this.props.data.designedObjects.nodes
    const {
        title,
        carousel,
        headerTextRight,
        headerTextLeft
    } = data.contentfulPaintings

    // const headerRight = this.props.data.contentfulPaintings?.headerTextRight
    // const headerLeft = this.props.data.contentfulPaintings?.headerTextLeft
    var carouselData = []
    var lightboxData = []
    carousel.map((item, i) => {
        carouselData.push({
            image: item.fluid.src,
            caption: i.toString()
        })
        lightboxData.push(item.fluid.src)
    })

    const captionStyle = {
        display: 'none'
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }
    return (
        <Layout>
            <div>
                <div>
                    <Carousel
                        data={carouselData}
                        toggler={toggler}
                        setToggler={setToggler}
                        time={2000}
                        width="850px"
                        height="500px"
                        captionStyle={captionStyle}
                        radius="10px"
                        slideNumber={true}
                        slideNumberStyle={slideNumberStyle}
                        captionPosition="bottom"
                        automatic={false}
                        dots={true}
                        pauseIconColor="white"
                        pauseIconSize="40px"
                        slideBackgroundColor="darkgrey"
                        slideImageFit="contain"
                        thumbnails={true}
                        thumbnailWidth="100px"
                        style={{
                            textAlign: "center",
                            maxWidth: "850px",
                            maxHeight: "500px",
                            marginBottom: '15rem'
                        }}
                    />

                </div>
                <FsLightbox
                    toggler={toggler.toggler}
                    sources={lightboxData}
                    slide={toggler.slide}
                />
                <div className="article-wrapper">
                    <h3 className="designed-object-title">
                        {title}
                    </h3>
                    <article className="designed-object-article">
                        <div className="article-right">
                            <p>
                                {headerTextLeft === null ? "" : documentToReactComponents(JSON.parse(headerTextLeft?.raw))}
                            </p>
                        </div>
                        <div className="article-left">
                            <p>
                                {headerTextRight === null ? "" : documentToReactComponents(JSON.parse(headerTextRight?.raw))}
                            </p>
                        </div>
                    </article>
                </div>
                <PaintingsGallery/>
            </div>
        </Layout>
    )

}

// DesignedObject.propTypes = propTypes

export const query = graphql`
query PaintingsQuery($slug: String!) {
    contentfulPaintings(slug: {eq: $slug}) {
        title
        slug
        id
        hero {
          id
          fluid {
            src
          }
        }
        headerTextRight {
            raw
        }
        headerTextLeft {
           raw
        }
        carousel {
          fluid {
            src
          }
        }
      }
}
`

export default DesignedObject

// export default(
//     graphql(
//       query1, 
//       {name: "data1"}
//     )(
//       graphql(
//         query2, 
//         {name: "data2"}
//       )(YourComponent)
//     )
//   )

